// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function () {};
	var methods = [
		"assert", "clear", "count", "debug", "dir", "dirxml", "error",
		"exception", "group", "groupCollapsed", "groupEnd", "info", "log",
		"markTimeline", "profile", "profileEnd", "table", "time", "timeEnd",
		"timeStamp", "trace", "warn"
	];
	var length = methods.length;
	var console = (window.console = window.console || {});
	while (length--) {
		method = methods[length];
		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());

// Place any jQuery/helper plugins in here.
$(document).ready(function($) {
	// detect window-size by grabbing body::before's contents
	function deviceType() {
		return window.getComputedStyle(document.querySelector('body'), '::before').getPropertyValue('content').replace(/"/g, '').replace(/'/g, '');
	}
	//check the media query and bind corresponding events
	var mediaquery = deviceType();
	bindEvents(mediaquery);
	$(window).on("resize", function () {
		mediaquery = deviceType();
		bindEvents(mediaquery);
	});
	function bindEvents(mediaquery) {
		if (mediaquery == 'desktop') {
			console.log('mediaquery: desktop');
		} else if (mediaquery === 'tablet') {
			console.log('mediaquery: tablet');
		} else if (mediaquery === 'mobile') {
			console.log('mediaquery: mobile');
		} else {
			console.log('Huh?')
		}
	}


	(function() {

		var indicator = $('#indicator');
		var counter = indicator.find('span');

		var win = jQuery(window);
		if (indicator.length) {
			var moveIndicator = debounce(function() {
				var viewportHeight = $(window).height();
				var documentHeight = $(document).height();
				var hasScrolled = $(window).scrollTop();

				var percent = (hasScrolled / (documentHeight - viewportHeight)) * 100;
				indicator.css("top", percent + "%");
				counter.html(Math.floor(percent) + "%");

			}, 10);

		}

		win.on("resize scroll", moveIndicator);

		function debounce(func, wait, immediate) {
			var timeout;

			return function() {
				var context = this,
					args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		};

	}());
	$('.scroll-indicator').css({'margin-bottom': '10px'})

});
